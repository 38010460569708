<template>
    <o-data-grid v-bind="dataLookupControl.gridOptions" :data-object="dataLookupControl.dataObject" :data="dataLookupControl.data" :columns="dataLookupControl.columns" ref="dataGridRef" :rowclickhandler="dataLookupControl.handleRowClick"
        :hide-multiselect-column="!dataLookupControl.multiselect" hide-action-column :activeRows="false" @mounted="onGridMounted" :row-class="getGridRowClass" :girdNavigationOverrides="dataLookupControl.girdNavigationOverrides"
            hide-grid-menu hide-new-records disable-batch-records disable-navigation hide-system-column :useLeftColumnSizers="dataLookupControl.useLeftSizers"  :persistentFilterId="dataLookupControl.persistentFilterId"
            :autoFilterDebounce="dataLookupControl.autoFilterDebounce" :useAutoFilter="!dataLookupControl.disableAutoFilter" is-lookup :nodeData="dataLookupControl.nodeData" :no-rows-found="noRowsFoundText"
            :dynamicLoading="(dataLookupControl.disableDynamicLoading || dataLookupControl.multiselect) ? false : undefined" :disableFilterRow="dataLookupControl.noSearch">
        <template #cardheader>
            <slot name="header"></slot>
            <div class="bg-light-subtle border-bottom w-100 h-100 hstack p-1 gap-2 hide-if-empty" style="flex:0 0 auto">
                <div v-if="showLimitToContextCheckbox" class="form-check">
                    <input class="form-check-input" type="checkbox" value="" :id="compInstanceId" v-model="bindToContext">
                    <label class="form-check-label" :for="compInstanceId">
                        {{ dataLookupControl.contextCheckboxLabel || $t('Restrict to context') }}
                    </label>
                </div>
                <slot name="toolbar"></slot>
            </div>
        </template>

        <template #statusbar>
            <o-info-items :data-object="dataLookupControl.dataObject" :noSelectClear="dataLookupControl.noClear" no-filter-string></o-info-items>
            <div class="ms-auto">
                <slot name="actions"></slot>
                <button v-if="dataLookupControl.multiselect" class="btn btn-sm btn-outline-primary" @click="dataLookupControl.handleMultiselect">{{$t('Select')}}</button>
                <button v-if="!dataLookupControl.noClear" class="btn btn-sm  btn-link " style="white-space:nowrap;float:right" 
                        @click="dataLookupControl.handleClearSelection">{{$t("Clear Input")}}</button>
            </div>
        </template>
        <slot></slot>
        
    </o-data-grid>

</template>
<script setup>
    import { ref,markRaw,watch, onMounted, watchEffect, computed } from 'vue';
    import { ODataGrid, OInfoItems } from 'o365-datagrid';
    import { $t } from 'o365-utils';
    
    import PinnedColumn from './desktop.PinnedColumn.vue';
    
    const props = defineProps({
        dataLookupControl: {
            required: true
        },
        dropdown: {
            required: false
        },
    });


    const compInstanceId = crypto.randomUUID();
    const dataGridRef = ref(null);
    const bindToContext = ref(!!props.dataLookupControl.contextFilterEnabled);
    const showLimitToContextCheckbox = computed(() => !!props.dataLookupControl.contextField || props.dataLookupControl.expandView);
    const noRowsFoundText = computed(() => {
        if (props.dataLookupControl.showRecentsAndPinnedOnly) {
            return $t('No pinned or recent selections, filter to see data...')
        } else {
            return undefined;
        }
    });

    watch(bindToContext, btc => {
        if (!props.dataLookupControl.contextField && !props.dataLookupControl.expandView) {
            return;
        }
        if (btc) {
            props.dataLookupControl.enableContextFilter();
        } else {
            props.dataLookupControl.disableContextFilter();
        }
        props.dataLookupControl.dataObject.load();
    });
    

    const onGridMounted = ()=>{
        props.dataLookupControl.noHeader =  dataGridRef.value.dataColumns.initialColumns.length == 1;
        props.dataLookupControl.columnsList = dataGridRef.value.dataColumns.initialColumns.map(x=> {return {name:x.field??x.name,type:x.type}});
        props.dataLookupControl.dataGridRef = dataGridRef.value;
        if(props.dataLookupControl.disablePinned) return;
        
        dataGridRef.value.dataGridControl.addColumn({
            field:"o365_pinned",
            width:0,
            minWidth:0,
            pinned:"right",
            headerName:"",
            sortable:false,
            filter:false,
            cellRenderer:markRaw(PinnedColumn),
            cellRendererParams:{pinnedClick:props.dataLookupControl.pinnedClick},
            disbaleMenu:true,
            disableResize:true,
            suppressMovable: true,
            suppressNavigable: true,
            suppressSelection: true,
        });
        if (props.dataLookupControl.focusSelector) {
            dataGridRef.value.dataGridControl.container.querySelector(props.dataLookupControl.focusSelector)?.focus();
        } else {
            dataGridRef.value.dataGridControl.header?.focusFirstFilterCell(props.dataLookupControl?.focusField);
        }
    }

    function getGridRowClass(row) {

        const sysClasses = {
            'o365-lookup-disabled': row.Disabled,
            'o365-lookup-recent': row._recent,
            'o365-lookup-pinned': row._pinned,
            'o365-body-row-pointer': true,
            'o365-lookup-active': row.index === props.dataLookupControl.navigation.currentIndex,
            'selectable': row.index === props.dataLookupControl.navigation.currentIndex && props.dataLookupControl.navigation.lastKeyIsNavigation,
        };
        if (props.dataLookupControl.rowClass) {
            return [sysClasses, props.dataLookupControl.rowClass(row)];
        } else {
            return sysClasses;
        }
    }

    onMounted(()=>{
        //set target
        dataGridRef.value.dataGridControl.header?.focusFirstFilterCell(props.dataLookupControl?.focusField);
    })
</script>
<style>
    .o365-body-row-pointer{
        cursor:pointer;
    }

    .hide-if-empty:empty {
        display: none;
    }
</style>